import React, { useState, useEffect } from 'react';

function Month() {
    const [data, setData] = useState([])
    const [precipitation, setPrecipitation] = useState({})
    const [precMax, setPrecMax] = useState(0)

    function daysInMonth(month, year) {
        return new Date(year, month, 0).getDate();
    }

    function getDay(date, idx) {
        const d = new Date(date.getFullYear(), date.getMonth(), (idx + 1))
        if (d.getMonth() !== date.getMonth()) return null
        return d
    }

    const realHeight = 200

    const getDateKey = (d) => d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate()

    useEffect(() => {
        Promise.all([fetch('/api/data').then(response => response.json()),
        fetch('/api/weather').then(response => response.json())])
            .then(result => {
                //split data into mon ths
                const [data, weather] = result
                let lastMonth = -1
                let monthData = []
                let monthDataList = []
                let maximum = 0
                data.forEach((item) => {
                    maximum = Math.max(maximum, item.w)
                })

                let keyCounter = 0
                let minDate = new Date(data[0].day)
                data.forEach((item) => {
                    let date = new Date(item.day)
                    let month = date.getMonth()
                    if (month !== lastMonth) {
                        //.slice(0, daysInMonth(date.getMonth() + 1, date.getFullYear()))
                        monthDataList = (new Array(31).fill({}).map((x, idx) => { return { key: keyCounter++, day: getDay(date, idx), height: 0 } }))
                        lastMonth = month
                        monthData.push({ key: keyCounter++, month: date.toLocaleString('sk', { month: 'long', year: 'numeric' }), data: monthDataList })
                    }
                    maximum = Math.max(maximum, item.w)
                    if (date < minDate) minDate = date
                    //monthDataList.push({ ...item, day: date, height: Math.round(item.w / maximum * realHeight) })
                    monthDataList[date.getDate() - 1] = { ...monthDataList[date.getDate() - 1], ...item, day: date, height: Math.round(item.w / maximum * realHeight) }
                })
                setData(monthData)
                //convert list to object
                const prec = {}
                weather.forEach((item) => {
                    const d = new Date(item.day)
                    if (d < minDate) return
                    prec[getDateKey(d)] = item.precipitation
                })
                setPrecipitation(prec)

                let pm = 0
                for (const q in prec) {
                    pm = Math.max(pm, prec[q])
                }
                setPrecMax(pm)
            })
    }, [])

    function getPrecipitation(item) {
        if (precMax && item.day) {
            const p = precipitation[getDateKey(item.day)]
            if (p) {
                const top = realHeight - (realHeight * p / precMax)
                return (<div style={{ position: 'absolute', top: top, left: 0, right: 0, bottom: 0, backgroundColor: '#FF8C00' }}></div>)
            }
        }
        return null
    }

    return (
        <div>
            {data.map((month) => {
                return (
                    <div key={month.key}>
                        <h1>{month.month}</h1>
                        <table style={{ width: '100%', tableLayout: 'fixed', border: '1px solid black' }}>
                            <tbody>
                                <tr style={{ height: '200px' }}>
                                    {month.data.map((item) => {
                                        return (
                                            <td key={item.key} style={{ verticalAlign: 'bottom', position: 'relative' }}>
                                                {getPrecipitation(item)}
                                                <div style={{ backgroundColor: '#4169E1', height: item.height, margin:'auto', width: '50%', position: 'absolute', bottom:0, left:0, right: 0 }}>&nbsp;</div>
                                            </td>
                                        )
                                    })}
                                </tr>
                                <tr>
                                    {month.data.map((item) => {
                                        return (
                                            <td key={item.key} style={{ borderTop: '1px solid black' }}>
                                                {item.day && item.day.getDate()}
                                            </td>
                                        )
                                    })}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                )
            })
            }
        </div>
    )
}

export default Month
